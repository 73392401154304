/* Weather.css */

.Weather {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;

  height: 75vh;
  width: 70vw;

  border-width: 20px;
  border-radius: 70px;
  border-color: black;

}
.weatherCloudAndPrecipitation-icon {
  width: 6vw;
  padding-top: 70%;
}
.wind-icon {
  width: 6vw;
  padding-top: 80%;
}
.vejrDataFraDmi{
  font-size:x-large;
  padding-top: 190%;
}