.weather-container{
  justify-content: space-between;
  justify-content:center;
  display: flex;
  flex-direction: row;
  /* align-items: flex-end; */
  position: relative;
  width: 20vw;
  padding-bottom: 3%;
}

.weather {
  
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 2vw;
  height: 22vh;
  align-items: center;
  padding-left: 12%;
  
}
.valueTemperatur {
  padding-right: 40%;
  padding-bottom: 0%;
  position: absolute;
  top: 3.6vh;
}

.value {
 margin-top: -4vh;
 padding-left: 0%;

}
.valuePrecipitation{
  padding-left: 40%;
  width: 4vw;
}
.weatherCloudAndPrecipitation-icon-container{
  display: flex;
  flex-direction: column;
  align-items: center; 
  padding-right: 0%;
  padding-left: 50%;
  margin-top: 0px;
  width: 2vw;
}

.wind-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center; 
  padding-right: 80%;
  margin-top: -2vh;
}




